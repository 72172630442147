import React from 'react';
import like from '../img/main-icons/like.svg'
import sale from '../img/main-icons/sale-fiolet.svg'
import flexPrice from '../img/main-icons/flex-price.svg'
import fixPrice from '../img/main-icons/fix-price.svg'
import znak from '../img/main-icons/znak.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Pagination} from "swiper";

const Advantage = () => {
    return <section className="advantage">
        <h2>Наши преимущества</h2>
        <div className="swiper advantage__container container" id='data-aos' data-aos="fade-right">
            <Swiper
                className="swiper-wrapper"
                slidesPerView={5}
                centeredSlides={true}
                spaceBetween={10}
                modules={[Pagination]}
                loop={true}

                pagination={{
                    clickable: true,
                    el: "#swiperPaginationAdvantage"
                }}
                breakpoints={{
                    '100': {
                        'slidesPerView': 1,
                        'slidesPerGroup': 1,
                        'spaceBetween': 30,
                        'centeredSlides': true
                    },
                    '500': {
                        'slidesPerView': 2,
                        'slidesPerGroup': 2,
                        'spaceBetween': 20,
                        'centeredSlides': false
                    },
                    '900': {
                        'slidesPerView': 4,
                        'slidesPerGroup': 1,
                        'spaceBetween': 15,
                        'centeredSlides': true
                    },
                    '1600': {
                        'slidesPerView': 5,
                        'slidesPerGroup': 0,
                        'spaceBetween': 10,
                        'centeredSlides': true,
                        'navigation' : false,
                        'pagination' : false,
                    }
                }}
            >
                <SwiperSlide className="swiper-slide">
                    <div className= "offer-item">
                        <img src={like} />
                        <h4>Вежливые сотрудники</h4>
                        <p>Все наши сотрудники научены вежливо общаться с клиентом</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className= "offer-item">
                        <img src={sale} />
                        <h4>Скидки и акции</h4>
                        <p>Наша компания предлагает различные акции, промокоды и скидки постоянным клиентам</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="offer-item">
                        <img src={flexPrice} />
                        <h4>Гибкая ценовая политика</h4>
                        <p>Наша политика гибких цен обязательно порадует Вас, если Вы выберите “Грузотаксишку”</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="offer-item">
                        <img src={fixPrice} />
                        <h4>Фиксированные цены за маршрут</h4>
                        <p>Если цена за маршрут была оговорена и установлена, то она уже не изменится, чтобы не
                        произошло</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="offer-item">
                        <img src={znak} />
                        <h4>Высокое качество выполнения услуг</h4>
                        <p>Мы оказываем наши услуги с 2005 года и всегда стараемся делать это на высочайшем уровне
                        обслуживания</p>
                    </div>
                </SwiperSlide>
                <div className="swiper-pagination" id='swiperPaginationAdvantage'/>
            </Swiper>
        </div>
    </section>
};

export default Advantage;