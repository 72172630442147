import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import mail from '../img/mail.svg'
import phone from '../img/phone.svg'
import phoneOld from '../img/phone-old.svg'
import logoTxt from '../img/logo-txt.svg'
import logo from '../img/logo.svg'
import home from '../img/menu-icons/home.svg'
import services from '../img/menu-icons/services.svg'
import cars from '../img/menu-icons/cars.svg'
import stuff from '../img/menu-icons/stuff.svg'
import partners from '../img/menu-icons/partners.svg'
import reviews from '../img/menu-icons/reviews.svg'
import contacts from '../img/menu-icons/contacts.svg'

const Header = ({setModalActive, setModalCallback}) => {

    const [activeBurger, setActiveBurger] = useState(false);

    const openModal = (e) => {
        e.preventDefault()
        setModalCallback(true)
        setModalActive(true)
        document.body.style.overflow = 'hidden';
    }
    const handlerLink = () => {
        setActiveBurger(!activeBurger);
        const body = document.querySelector('#root');
        body.scrollIntoView({behavior: 'smooth'}, 500)
    }

    return <header className="header" >
        <div className="top-header">
            <div className="flex-container">
                <a href="mailto:info@gruzotaxishka.ru"><img src={mail} alt='Грузотаксишка' /> info@gruzotaxishka.ru</a>
                <a href="tel:8 (911) 247-11-32"><img src={phone} alt='Грузотаксишка' /> 8 (911) 247-11-32</a>
                <a href="tel:8 (911) 923-24-99"><img src={phone} alt='Грузотаксишка' />8 (911) 923-24-99</a>
            </div>
        </div>
        <div className="header-body">
            <div className="container">
                <div className="flex-container">
                        <div className={activeBurger ? 'header__burger active' : 'header__burger'}
                             onClick={() => setActiveBurger(!activeBurger)}>
                            <span />
                            <p>{activeBurger ? 'закрыть' : 'меню'}</p>
                        </div>
                    <a href="tel:8 (911) 923-24-99" className='logo-txt'>
                        <img src={logoTxt} alt="Грузотаксишка"/>
                    </a>

                    <div className={activeBurger ? 'header__navigation active' : 'header__navigation'}>
                        <nav className="navigation">
                            <ul className="menu topmenu">
                                <a href="tel:8 (911) 247-11-32"><img src={logo} alt='Грузотаксишка'/></a>
                                <li><img src={home} className='menu-icon'/><NavLink to="/" className="link-btn" onClick={handlerLink}>Главная</NavLink></li>
                                <li><img src={services} className='menu-icon'/><NavLink to="/uslugi-i-tseny" className="link-btn" onClick={handlerLink}>Услуги и цены</NavLink></li>
                                <li><img src={cars} className='menu-icon'/><NavLink to="/nashi-mashini" className="link-btn" onClick={handlerLink}>Машины</NavLink></li>
                                <li><img src={stuff} className='menu-icon'/><NavLink to="/nashi-gruzchiki" className="link-btn" onClick={handlerLink}>Грузчики</NavLink></li>
                                <li><img src={partners} className='menu-icon'/><NavLink to="/partners" className="link-btn" onClick={handlerLink}>Партнеры</NavLink></li>
                                <li><img src={reviews} className='menu-icon'/><NavLink to="/otzyvy" className="link-btn" id="contact-btn" onClick={handlerLink}>Отзывы</NavLink></li>
                                <li><img src={contacts} className='menu-icon'/><NavLink to="/kontakty" className="link-btn" id="contact-btn" onClick={handlerLink}>Контакты</NavLink></li>
                                <button
                                    className='btn callback-btn'
                                    onClick={e=>openModal(e)}
                                >
                                    Заказать звонок
                                </button>
                                <a href="tel:8 (911) 247-11-32" className='btn call-btn'>Позвонить</a>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
};

export default Header;