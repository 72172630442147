import React from 'react';
import {NavLink} from "react-router-dom";
import mail from '../img/email-yellow.svg'
import phone from '../img/phone-yellow.svg'
import vk from '../img/vk-yellow.svg'

const Footer = () => {
    return <footer className="footer" id="contact">
        <div className="yellow-footer">
            <h2>Грузоперевозки по Гатчине, СПБ и Ленинградской области</h2>
        </div>
        <div className="footer-content">
            <div className="container">
                <h2>"Грузотаксишка"</h2>
                <ul className="menu bottomMenu">
                    <li><NavLink to="/" className="link-btn">Главная</NavLink></li>
                    <li><NavLink to="uslugi-i-tseny" className="link-btn">Услуги и цены</NavLink></li>
                    <li><NavLink to="nashi-mashini" className="link-btn">Наши машины</NavLink></li>
                    <li><NavLink to="nashi-gruzchiki" className="link-btn">Наши грузчики</NavLink></li>
                    <li><NavLink to="partners" className="link-btn">Наши Партнеры</NavLink></li>
                    <li><NavLink to="otzyvy" className="link-btn" id="contact-btn">Отзывы</NavLink></li>
                    <li><NavLink to="kontakty" className="link-btn" id="contact-btn">Контакты</NavLink></li>

                </ul>
                <div className="footer__contacts">
                    <a href="mailto:info@gruzotaxishka.ru"><img src={mail} /> info@gruzotaxishka.ru</a>
                    <a href="tel:8 (911) 247-11-32"><img src={phone} /> 8 (911) 247-11-32</a>
                    <a href="tel:8 (911) 923-24-99"><img src={phone} />8 (911) 923-24-99</a>
                    <a href="https://vk.com/club.gruzotacsichka47" target="blank"><img src={vk} />club.gruzotacsichka47</a>
                </div>
            </div>
        </div>
    </footer>
};

export default Footer;