import React, {useEffect} from 'react';

import Aos from "aos";

import {Helmet} from "react-helmet";

import Utp from "../components/Utp";
import Offer from "../components/Offer";
import Advantage from "../components/Advantage";
import piano from "../img/services/piano.svg";
import phone from "../img/phone-fiolet.svg";
import seif from "../img/services/seif.svg";
import vkFull from "../img/vk-full.svg";
import Coupons from "../components/Coupons";


const Home = ({setModalActive, setContextModal}) => {

    const openModal = (e) => {
        e.preventDefault()
        setModalActive(true)
        setContextModal(e.target.name)
        document.body.style.overflow = 'hidden';
    }

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({behavior: 'smooth'}, 500)
        document.body.style.overflowX = 'hidden';
        if (window.innerWidth > 900) {
            Aos.init({duration: 1000, delay: 0, once: true});
            Aos.refresh();
        } else if (window.innerWidth <= 900) {
            let dataAos = document.querySelectorAll('#data-aos');
            for  (let data of dataAos) {
                delete data.dataset.aos
            }
        }
    }, []);

    return <>
        <Helmet >
            <html lang="ru" />
            <title>Грузотаксишка - грузоперевозки по Гатчине, СПБ и Ленинградской области</title>
            <meta name="description" content="Компания Грузотаксишка предоставляет автотранспортные грузоперевозки по Гатчине, СПБ и Ленинградской области уже долгие годы!"/>
            <meta name="keywords" content="грузотаксишка, грузоперевозки, гатчина, спб, автотранспорт" />
        </Helmet>
            <div className="phones flex-container">
                <a href="tel:8 (911) 247-11-32" className='phone-home'><img src={phone} /> 8 (911) 247-11-32</a>
                <a href="tel:8 (911) 923-24-99" className='phone-home'><img src={phone} />8 (911) 923-24-99</a>
            </div>

            <h1>Грузотаксишка - квартирные, офисные и дачные грузоперевозки по Гатчине, СПБ и Ленинградской области</h1>

            <Utp setModalActive={setModalActive}  setContextModal={setContextModal}/>

            <Coupons />

            <div className="piano container">
                <img src={piano}  id='data-aos' data-aos="fade-right"/>
                <div className="piano_text"  id='data-aos' data-aos="fade-left">
                    <h1>Нужно перевезти тяжелое пианино?</h1>
                    <p>При переезде квартиры часто встает вопрос о перевозке пианино, рояля или фортепиано, а для
                        его транспортировки нужны крепкие мужские руки. Наши профессиональные грузчики не только
                        находятся в отличной физической форме, но и обладают аккуратностью и бережным отношением ко
                        всем видам грузов, в том числе и к вашему музыкальному инструменту. С нами перевозка пианино
                        пройдет легко, качественно и недорого.</p>
                    <p className='price'>Перевозка пианино от 2000р</p>
                    <button className="btn" name='перевозка пианино' onClick={e=>openModal(e)}>Оставить заявку</button>
                </div>
            </div>

            <div className="seif container">
                <img src={seif}  id='data-aos' data-aos="fade-left"/>
                <div className="seif_text"  id='data-aos' data-aos="fade-right">
                    <h1>Перевозка сейфов</h1>
                    <p>Перевозка сейфа – это технически сложное и ответственное мероприятие,
                        осуществить которое неподготовленному человеку просто не под силу.
                        А все потому, что сейф относится к разряду специфических тяжелых грузов
                        и в большинстве случаев отличается нестандартными габаритами.
                        Наша компания имеет все необходимое оснащение для перевозки сейфов любой конфигурации:
                        надежное такелажное оборудование, качественный инструмент и современный специализированный транспорт.
                        Кроме того, штат компании укомплектован профессиональными специалистами, изучившими все нюансы данной работы.
                    </p>
                    <p className='price'>Перевозка сейфов от 2000р</p>
                    <button className="btn" name='перевозка сейфов' onClick={e=>openModal(e)}>Оставить заявку</button>
                </div>
            </div>

            <div className="vk-section">
                <img src={vkFull} alt="Грузотаксишка ВК" id='data-aos' data-aos="fade-left"/>
                <h1 id='data-aos' data-aos="fade-left">Узнать более подробно о нашей компании
                    или оставить отзыв можно в нашей группе Вконтакте
                </h1>
                <a href="https://vk.com/club.gruzotacsichka47" target="blank" id='data-aos' data-aos="fade-left">
                    Ознакомиться с группой Вконтакте
                </a>
            </div>

            <div id='data-aos' data-aos="fade-right"> <Offer /> </div>
            <div id='data-aos' data-aos="fade-right"> <Advantage /> </div>
    </>
}

export default Home;