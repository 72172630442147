import React from 'react';
import Aos from "aos";
import novosel from '../coupons/cupon-novosel.pdf';
import Gatchina from '../coupons/cupon-Gatchina.pdf';
import mnogodet from '../coupons/cupon-mnogodet.pdf';
import pensioner from '../coupons/cupon-pensioner.pdf';

const Coupons = () => {
    return <section className="coupons-section">
        <h2>Скидки и купоны</h2>
        <div className="container">
            <div className="coupons flex-container">
                <div className="coupon coupon__fiolet">
                    <h3>Купон для новоселов!</h3>
                    <p>Предоставляем скидку <span>15%</span> новосёлам!</p>
                    <a href={novosel} target='_blank' className='btn coupon-btn'>Сохранить/распечатать купон</a>
                </div>
                <div className="coupon coupon__yellow">
                    <h3>Купон жителям Гатчины!</h3>
                    <p>Предоставляем скидку <span>15%</span> для жителей Гатчины!</p>
                    <a href={Gatchina} target='_blank' className='btn coupon-btn'>Сохранить/распечатать купон</a>
                </div>
                <div className="coupon coupon__yellow">
                    <h3>Купон пенсионерам!</h3>
                    <p>Предоставляем скидку <span>15%</span> для пенсионеров!</p>
                    <a href={pensioner} target='_blank' className='btn coupon-btn'>Сохранить/распечатать купон</a>
                </div>
                <div className="coupon coupon__fiolet">
                    <h3>Купон для многодетных!</h3>
                    <p>Предоставляем скидку <span>15%</span> многодетным семьям!</p>
                    <a href={mnogodet} target='_blank' className='btn coupon-btn'>Сохранить/распечатать купон</a>
                </div>
            </div>
            <p>
                <span>Для использования купона:</span>
                <ol>
                    <li>
                        Распечатайте купон, чтобы отдать его водителю или сохраните<span style={{color: 'red'}}>*</span> его на мобильном устройстве.
                    </li>
                    <li>
                        Предоставьте документ, подтверждающий Ваш статус, в соответствии с купоном:
                        <ul>
                            <li>Ордер на квартиру</li>
                            <li>Прописку</li>
                            <li>Пенсионное удостоверение</li>
                            <li>Свидетельство многодетной семьи</li>
                        </ul>
                    </li>
                </ol>
                <p><span style={{color: 'red'}}>*</span> Если вы сохранили купон на мобильном телефоне, водителю понадобится сделать фото Вас с открытым купоном на Вашем телефоне. <br/>
                    Если Вы хотите воспользоваться купоном другим способом, тогда заранее распечатайте купон и отдайте его водителю!</p>
            </p>
        </div>

    </section>
};

export default Coupons;