import React from 'react';
import individual from '../img/main-icons/individual.svg'
import sale from '../img/main-icons/sale.svg'
import home from '../img/main-icons/home.svg'
import office from '../img/main-icons/office.svg'
import village from '../img/main-icons/village.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, Pagination} from "swiper";

const Offer = () => {
    return <section className="offer">
        <h2>Что мы предлагаем</h2>
        <div className="swiper offer__container container" id='data-aos' data-aos="fade-right">
            <Swiper
                className="swiper-wrapper"
                slidesPerView={5}
                centeredSlides={true}
                spaceBetween={10}
                modules={[Pagination]}
                loop={true}

                pagination={{
                    clickable: true,
                    el: "#swiperPaginationOffer"
                }}
                breakpoints={{
                    '100': {
                        'slidesPerView': 1,
                        'slidesPerGroup': 1,
                        'spaceBetween': 30,
                        'centeredSlides': true
                    },
                    '500': {
                        'slidesPerView': 2,
                        'slidesPerGroup': 2,
                        'spaceBetween': 20,
                        'centeredSlides': false
                    },
                    '900': {
                        'slidesPerView': 4,
                        'slidesPerGroup': 1,
                        'spaceBetween': 15,
                        'centeredSlides': true
                    },
                    '1600': {
                        'slidesPerView': 5,
                        'slidesPerGroup': 0,
                        'spaceBetween': 10,
                        'centeredSlides': true,
                        'navigation' : false,
                        'pagination' : false,
                    }
                }}
            >
                <SwiperSlide className="swiper-slide">
                    <div className="offer-item">
                        <img src={individual} alt='individual'/>
                        <h4>Индивидуальный подход</h4>
                        <p>Найдем подходу к каждому клиенту</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="offer-item">
                        <img src={sale} alt='sale'/>
                        <h4>Скидки постоянным клиентам</h4>
                        <p>Пользуясь нашими услугами, Вы сможете получить приятную скидку постоянного клиента</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="offer-item">
                        <img src={home} alt='home'/>
                        <h4>Квартирный переезд</h4>
                        <p>Осуществим Ваш переезд с погрузкой и доставкой, даже если вы живете на 25 этаже</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="offer-item">
                        <img src={office} alt='office'/>
                        <h4>Офисный переезд</h4>
                        <p>Поможем бережно осуществить переезд целого офиса</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="offer-item">
                        <img src={village} alt='village'/>
                        <h4>Дачный переезд</h4>
                        <p>Мы работаем не только в городе, но и за его пределами!</p>
                    </div>
                </SwiperSlide>
                <div className="swiper-pagination" id='swiperPaginationOffer'/>
            </Swiper>
        </div>
    </section>
};

export default Offer;