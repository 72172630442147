import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import kvartir from '../img/services/kvartir.svg'
import dacha from '../img/services/dacha.svg'
import musor from '../img/services/musor.svg'
import piano from '../img/services/piano.svg'
import seif from '../img/services/seif.svg'
import office from '../img/services/office.svg'
import dop from '../img/services/dop.svg'
import Aos from "aos";
import phoneOld from "../img/phone-fiolet.svg";

const Services = ({setModalActive, setContextModal}) => {

    const openModal = (e) => {
        e.preventDefault()
        setModalActive(true)
        setContextModal(e.target.name)
        document.body.style.overflow = 'hidden';
    }

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({behavior: 'smooth'}, 500)
        document.body.style.overflowX = 'hidden';
        if (window.innerWidth > 900) {
            Aos.init({duration: 1000, delay: 0, once: true});
            Aos.refresh();
        } else if (window.innerWidth <= 900) {
            let dataAos = document.querySelectorAll('#data-aos');
            for  (let data of dataAos) {
                delete data.dataset.aos
            }
        }
    }, []);

    return <div className='services-page container'>
        <Helmet >
            <html lang="ru" />
            <title>Услуги цены</title>
            <meta name="keywords" content="квартирный переезд, офисный переезд, дачный переезд, услуги грузчиков, грузоперевозки, гатчина, санкт-петербург, ленинградская область, россия" />
            <meta name="description" content="Наша компания предоставляет различные услуги связанные с переездом и перевозками: Квартирный переезд, офисный переезд, дачный переезд, услуги грузчиков в Гатчине, СПБ и ЛО." />
        </Helmet>
        <div className="phones flex-container">
            <a href="tel:8 (911) 247-11-32" className='phone-home'><img src={phoneOld} />8 (911) 247-11-32</a>
            <a href="tel:8 (911) 923-24-99" className='phone-home'><img src={phoneOld} />8 (911) 923-24-99</a>
        </div>
        <h1>Услуги и цены</h1>
        <p>Мы представляем Вам наши услуги и цены на них. Цены указаны для стандартного переезда, но так как каждый
            заказ индивидуальный то лучше оговаривать его по телефону, указанному на сайте.</p>
        <div className="services__content">
            <div className="services__item">
                <img src={kvartir}  id='data-aos' data-aos="fade-right"/>
                <div className="service__item_text" id='data-aos' data-aos="fade-left">
                    <h1>Квартирный переезд</h1>
                    <p>Мало кто сегодня может реально предложить квартирный переезд дешево в Гатчине и СПб. Если вас
                        заманивают чересчур низкими ценами, чаще всего это оборачивается дополнительными расходами,
                        низким качеством, потраченными нервами и поврежденным имуществом. Решив перевезти квартиру с
                        нашей помощью, вы страхуете себя от подобных проблем.</p>
                    <p>Мы обеспечим:</p>
                    <ul>
                        <li>Своевременность прибытия бригады грузчиков и автомобиля</li>
                        <li>Высокое качество работ, включая сборку-разборку мебели, упаковку хрупких и деликатных
                            вещей и т.д.
                        </li>
                        <li>Оптимальную стоимость, в том числе для срочных заказов</li>
                        <li>Вежливых и опрятных сотрудников</li>
                        <li>Полную материальную ответственность за вверенное имущество</li>
                    </ul>
                    <p className='price'>
                        Машина без грузчиков 1400 руб - час работы
                        <br/>
                        Машина с 2 мя грузчиками 1800 руб - час работы
                    </p>

                    <button className="btn" name='Квартирный переезд' onClick={e=>openModal(e)}>Оставить заявку</button>
                </div>
            </div>
            <div className="services__item">
                <div className="service__item_text" id='data-aos' data-aos="fade-right">
                    <h1>Офисный переезд</h1>
                    <p>Офисный переезд в Гатчине, СПБ и регионе - недорого, быстро и качественно. Переезд офиса в
                        удобное для Вас время с гарантией сохранности вещей.</p>
                    <p>Когда на горизонте маячит переезд в новый офис, начинают мелькать мысли. В голове кружатся
                        десятки вопросов. Когда его осуществить? Где и у кого заказать переезд – недорого, быстро и
                        качественно? Какие гарантии предоставит компания-перевозчик?«Переживут» ли переезд дорогое
                        офисное оборудование, важные документы, личные вещи сотрудников? Как не потерять клиентов и
                        контракты во время вынужденной паузы?</p>
                    <p>Наша компания гарантирует Вам сохранность Ваших документов, мебели, сейфов и орг техники. Переезд
                        с нами будет комфортным и удобным.</p>
                    <p className='price'>
                        Машина без грузчиков 1400 руб - час работы
                        <br/>
                        Машина с 2 мя грузчиками 1800 руб - час работы
                    </p>
                    <button className="btn" name='Офисный переезд' onClick={e=>openModal(e)}>Оставить заявку</button>

                </div>
                <img src={office}  id='data-aos' data-aos="fade-left"/>
            </div>
            <div className="services__item">
                <img src={dacha}  id='data-aos' data-aos="fade-right"/>
                <div className="service__item_text"  id='data-aos' data-aos="fade-left">
                    <h1>Дачный переезд</h1>
                    <p>Для тех, кто имеет дачу, загородный домик является синонимом комфорта, отдыха и спокойствия.
                        На дачу едут, чтобы заняться рыбалкой, приготовить с друзьями шашлыки и приятно провести
                        время.</p>
                    <p>Многие содержат небольшие огороды и сады, работают на земле. Поездка на дачу – всегда
                        радостное событие для всех членов семьи. Но лишь в том случае, когда дело не идёт о переезде
                        или транспортировке крупногабаритных и тяжёлых вещей. Ведь как только потребуется перевезти
                        на дачу или с дачи мебель, бытовую технику, различные вещи и даже консервацию - дачный
                        переезд сразу превращается в головную боль.</p>
                    <p>Чтобы избежать лишних проблем и лишь наслаждаться загородным отдыхом, доверьте переезд
                        профессионалам, воспользовавшись услугами нашей компании.</p>
                    <p className='price'>
                        Машина без грузчиков 1400 руб - час работы
                        <br/>
                        Машина с 2 мя грузчиками 1800 руб - час работы
                    </p>
                    <button className="btn" name='Дачный переезд' onClick={e=>openModal(e)}>Оставить заявку</button>
                </div>
            </div>
            <div className="services__item">
                <div className="service__item_text"  id='data-aos' data-aos="fade-right">
                    <h1>Вывоз мусора</h1>
                    <p>Наша компания увозит мусор любой категории:</p>
                    <ul>
                        <li>бытовой (пищевые отходы, пластик, металлы, дерево)</li>
                        <li>строительный (песок, штукатурка, плитка, кирпич)</li>
                        <li>производственный (остатки сырья, различных материалов, образовавшихся в результате
                            деятельности производственных предприятий)
                        </li>
                    </ul>
                    <p>Вывоз мусора необходимо выполнять своевременно. Неубранные массы становятся токсичными, отравляют
                        землю и грунтовые воды. Такой мусор представляет опасность не только для окружающей среды, но и
                        для здоровья человека. Строительный мусор, оставшийся после ремонта, мешает как самим владельцам
                        квартиры, так и соседям. Мы осуществляем вывоз мусора в Гатчине не только из квартир и дворов,
                        но и с фабрик, заводов, стройплощадок.</p>
                    <p className='price'>Вывоз мусора от 2000р</p>
                    <button className="btn" name='Вывоз мусора' onClick={e=>openModal(e)}>Оставить заявку</button>
                </div>
                <img src={musor} id='data-aos' data-aos="fade-left" />
            </div>
            <div className="services__item">
                <img src={piano}  id='data-aos' data-aos="fade-right"/>
                <div className="service__item_text"  id='data-aos' data-aos="fade-left">
                    <h1>Перевозка пианино</h1>
                    <p>При переезде квартиры часто встает вопрос о перевозке пианино, рояля или фортепиано, а для
                        его транспортировки нужны крепкие мужские руки. Наши профессиональные грузчики не только
                        находятся в отличной физической форме, но и обладают аккуратностью и бережным отношением ко
                        всем видам грузов, в том числе и к вашему музыкальному инструменту. С нами перевозка пианино
                        пройдет легко, качественно и недорого.</p>
                    <p className='price'>Перевозка пианино от 2000р</p>
                    <button className="btn" name='Перевозка пианино' onClick={e=>openModal(e)}>Оставить заявку</button>
                </div>
            </div>
            <div className="services__item">
                <div className="service__item_text"  id='data-aos' data-aos="fade-right">
                    <h1>Перевозка сейфов</h1>
                    <p>Перевозка сейфа – это технически сложное и ответственное мероприятие, осуществить которое
                        неподготовленному человеку просто не под силу. А все потому, что сейф относится к разряду
                        специфических тяжелых грузов и в большинстве случаев отличается нестандартными габаритами. Наша
                        компания имеет все необходимое оснащение для перевозки сейфов любой конфигурации: надежное
                        такелажное оборудование, качественный инструмент и современный специализированный транспорт.
                        Кроме того, штат компании укомплектован профессиональными специалистами, изучившими все нюансы
                        данной работы.</p>
                    <p className='price'>Перевозка сейфов от 2000р</p>
                    <button className="btn" name='Перевозка сейфов' onClick={e=>openModal(e)}>Оставить заявку</button>
                </div>
                <img src={seif}  id='data-aos' data-aos="fade-left"/>
            </div>
            <div className="services__item">
                <img src={office}  id='data-aos' data-aos="fade-right"/>
                <div className="service__item_text"  id='data-aos' data-aos="fade-left">
                    <h1>Услуги грузчиков</h1>
                    <p>Наша компания готова предложить услуги грузчиков для осуществления квартирного, офисного,
                        дачного переезда, перестановки оборудования или мебели, а также выполнения такелажных работ.
                        Мы тщательно подбираем персонал, предъявляя высокие требования к каждому претенденту,
                        поэтому вы можете быть уверенными в профессионализме, аккуратности и ответственности наших
                        работников. Заказав переезд с грузчиками, вы можете рассчитывать на помощь
                        квалифицированных, сильных и выносливых специалистов, которые выполнят свою работу
                        максимально быстро, уложившись в оговоренные сроки.</p>
                    <p className='price'>Грузчики - 500р/ч</p>
                    <button className="btn" name='Услуги грузчиков'  onClick={e=>openModal(e)}>Оставить заявку</button>

                </div>
            </div>
            <div className="services__item">
                <div className="service__item_text"  id='data-aos' data-aos="fade-right">
                    <h1>Дополнительные услуги</h1>
                    <p>Наша компания готова предложить дополнительные услуги.</p>
                    <p className='price'>Машина экспресс - 1200р (15 мин загрузка 15 разгрузка в пределах Гатчины)</p>
                    <button className="btn" name='Дополнительные услуги' onClick={e=>openModal(e)}>Оставить заявку</button>
                </div>
                <img src={dop}  id='data-aos' data-aos="fade-left"/>
            </div>
        </div>
    </div>
}

export default Services;