import React, {useEffect} from 'react';
import utp from '../img/utp.svg'
import Aos from "aos";

const Utp = ({setModalActive, setContextModal}) => {

    const openModal = (e) => {
        e.preventDefault()
        setModalActive(true)
        setContextModal(e.target.name)
        document.body.style.overflow = 'hidden';
    }

    useEffect(() => {
        if (window.innerWidth > 900) {
            Aos.init({duration: 1000, delay: 0, once: true});
            Aos.refresh();
        } else if (window.innerWidth <= 900) {
            let dataAos = document.querySelectorAll('#data-aos');
            for  (let data of dataAos) {
                delete data.dataset.aos
            }
        }
    }, []);

    return <section className="utp">
        <div className="container flex-container">
            <div className="utp__text">
                <img src={utp} alt="Грузотаксишка" />
                <p id='data-aos' data-aos="fade-right" data-aos-delay="100">Основное направление деятельности нашей компании <b>автотранспортные грузоперевозки по Гатчине, СПБ и
                    Ленинградской области.</b></p>

                <p id='data-aos' data-aos="fade-right" data-aos-delay="100">Например: клиенту необходимо перевезти вещи из одной трехкомнатной квартиры в другую. Вещей, как и
                    следует ожидать, очень много. Причем, некоторые из них хрупкие и ценные.
                    Другие компании, скорее всего, предложат перевезти всё за несколько заходов, покидают вещи в кузов и
                    попросят дополнительную плату за помощь во время погрузки и разгрузки.</p>

                <p id='data-aos' data-aos="fade-right" data-aos-delay="200">В нашей компании вам предложат уникальную услугу с фиксированной ценой.</p>

                <p id='data-aos' data-aos="fade-right" data-aos-delay="300">Мы быстро, бережно и надежно осуществим грузоперевозки по Санкт-Петербургу и Ленинградской области,
                    а также по России. Высокая квалификация наших водителей и грузчиков, а также наличие хорошей грузовой техники,
                    гарантируют сохранность Вашего имущества.</p>
                <p id='data-aos' data-aos="fade-right" data-aos-delay="400">Мы с 2005 года работаем в этой сфере. Для организации доставки используется как собственный, так и
                    привлеченный автотранспорт грузоподъемностью от 1,5 до 20 т с разными типами кузова.</p>
                <button
                    className="btn btn__open-modal"
                    name='Кнопка связаться для перевозки'
                    id='data-aos'
                    data-aos="fade-right"
                    data-aos-delay="1000"
                    onClick={e=>openModal(e)}
                >
                    Связаться для перевозки
                </button>
            </div>
            <div className="utp__img"  id='data-aos' data-aos="fade-left">
                <img src={utp} alt="Грузотаксишка" />
            </div>
        </div>
    </section>
};

export default Utp;