import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import partnersA from '../img/partners/1.png'
import partnersB from '../img/partners/2.png'
import partnersC from '../img/partners/3.png'
import partnersD from '../img/partners/4.png'
import partnersE from '../img/partners/5.png'
import partnersF from '../img/partners/6.png'
import partnersG from '../img/partners/7.png'
import partnersH from '../img/partners/8.png'
import partnersK from '../img/partners/9.png'
import partnersL from '../img/partners/10.png'
import partnersM from '../img/partners/11.png'
import Aos from "aos";
import phoneOld from "../img/phone-fiolet.svg";

const Partners = () => {

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({behavior: 'smooth'}, 500)
        document.body.style.overflowX = 'hidden';
        if (window.innerWidth > 900) {
            Aos.init({duration: 1000, delay: 0, once: true});
            Aos.refresh();
        } else if (window.innerWidth <= 900) {
            let dataAos = document.querySelectorAll('#data-aos');
            for  (let data of dataAos) {
                delete data.dataset.aos
            }
        }
    }, []);

    return <div className='partners container'>
        <Helmet >
            <html lang="ru" />
            <title>Наши Партнёры</title>
            <meta name="description" content="Грузотаксишка занимается перевозками уже много лет и имеет большое количество партнеров. Ознакомьтесь на нашем сайте." />
            <meta name="keywords" content="наши партнёры, грузотаксишка, гатчина, СПБ, ленинградская область"/>
        </Helmet>
        <div className="phones flex-container">
            <a href="tel:8 (911) 247-11-32" className='phone-home'><img src={phoneOld} /> 8 (911) 247-11-32</a>
            <a href="tel:8 (911) 923-24-99" className='phone-home'><img src={phoneOld} />8 (911) 923-24-99</a>
        </div>
        <h1>Партнеры</h1>
        <div className="container">
            <div className='flex-container'>
                <img src={partnersA} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="0"/>
                <img src={partnersB} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="100"/>
                <img src={partnersC} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="200"/>
            </div>
            <div className='flex-container'>
                <img src={partnersD} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="400"/>
                <img src={partnersE} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="500"/>
                <img src={partnersF} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="600"/>
                <img src={partnersG} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="700"/>
            </div>
            <div className='flex-container'>
                <img src={partnersH} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="800"/>
                <img src={partnersK} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="900"/>
                <img src={partnersL} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="1000"/>
                <img src={partnersM} alt="Партнеры" id='data-aos' data-aos="fade-left" data-aos-delay="1100"/>
            </div>
        </div>
    </div>
};

export default Partners;