import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, Pagination} from "swiper";
import client from "../img/client.svg";
import Aos from "aos";
import phoneOld from "../img/phone-fiolet.svg";

const Reviews = () => {

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({behavior: 'smooth'}, 500)
        document.body.style.overflowX = 'hidden';
        if (window.innerWidth > 900) {
            Aos.init({duration: 1000, delay: 0, once: true});
            Aos.refresh();
        } else if (window.innerWidth <= 900) {
            let dataAos = document.querySelectorAll('#data-aos');
            for  (let data of dataAos) {
                delete data.dataset.aos
            }
        }
    }, []);

    return <div className='reviews'>
        <Helmet >
            <html lang="ru" />
            <title>Грузотаксишка - отзывы</title>
            <meta name="keywords" content="отзывы, грузотаксишка, гатчина, СПБ, ленинградская область" />
            <meta name="description" content="Отзывы о компании Грузотаксишка" />
        </Helmet>
        <div className="phones flex-container">
            <a href="tel:8 (911) 247-11-32" className='phone-home'><img src={phoneOld} /> 8 (911) 247-11-32</a>
            <a href="tel:8 (911) 923-24-99" className='phone-home'><img src={phoneOld} />8 (911) 923-24-99</a>
        </div>
        <h1>Отзывы</h1>
        <div className="swiper reviews__container container" id='data-aos' data-aos="fade-left">
            <Swiper
                className="swiper-wrapper"
                slidesPerView={4}
                centeredSlides={true}
                spaceBetween={32}
                modules={[Navigation, Pagination]}
                loop={true}
                navigation={{
                    prevEl: "#swiperPrevReviews",
                    nextEl: "#swiperNextReviews"
                }}
                pagination={{
                    clickable: true,
                    el: "#swiperPaginationReviews"
                }}
                breakpoints={{
                    '100': {
                        'slidesPerView': 1,
                        'slidesPerGroup': 1,
                        'spaceBetween': 20,
                        'centeredSlides': true
                    },
                    '600': {
                        'slidesPerView': 2,
                        'slidesPerGroup': 1,
                        'spaceBetween': 30,
                    },
                    '900': {
                        'slidesPerView': 3,
                        'slidesPerGroup': 1,
                        'centeredSlides': true
                    },
                    '1600': {
                        'slidesPerView': 4,
                        'slidesPerGroup': 1,
                    }
                }}
            >
                <SwiperSlide className="swiper-slide">
                    <img src={client} alt='Грузотаксишка'/>
                    <h3>Максим Ефимов</h3>
                    <p>
                        Помогли и выручили на 5+ !!!
                        Хочу отметить особую оперативность с доставкой и подъемом, всем бы поучиться у ребят как надо работать!!!
                        Спасибо!
                    </p>
                    <span>5 февраля 2012</span>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={client} alt='Грузотаксишка'/>
                    <h3>Андрей Касыянык</h3>
                    <p>
                        Очень быстро среагировали на запрос! Приехали вовремя все по высшему разряду! СПАСИБО!
                    </p>
                    <span>3 апреля 2016</span>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={client} alt='Грузотаксишка'/>
                    <h3>Елена Мишина</h3>
                    <p>
                        Спасибо большое! Вчера был переезд, все прошло очень быстро, вовремя.
                        Ребята вежливые, приятные и аккуратные. Без перекуров и проволочек, в общем молодцы!
                    </p>
                    <span>10 марта 2018</span>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={client} alt='Грузотаксишка'/>
                    <h3>Владислав Власов</h3>
                    <p>
                        Доброго вечера,
                        Спасибо за оперативность, качество, быстроту, аккуратность и самое главное все довольны.
                        Рекомендую, высокая оценка труда. Спасибо
                    </p>
                    <span>17 апреля 2022</span>
                </SwiperSlide>
                <div className="swiper-pagination" id='swiperPaginationReviews'/>
            </Swiper>
            <div className="swiper-button-next" id='swiperNextReviews'/>
            <div className="swiper-button-prev" id='swiperPrevReviews'/>
        </div>
        <p>Вы можете оставить отзыв или <a href='https://vk.com/topic-32210533_26024093'>ознакомиться</a> с другими отзывами в нашей группе Вконтакте</p>

    </div>
};

export default Reviews;