import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import {Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import stuff1 from '../img/stuff/1.jpg'
import stuff2 from '../img/stuff/2.jpg'
import stuff3 from '../img/stuff/3.jpg'
import stuff4 from '../img/stuff/4.jpg'
import stuff5 from '../img/stuff/5.jpg'
import stuff6 from '../img/stuff/6.jpg'
import stuff7 from '../img/stuff/7.jpg'
import stuff8 from '../img/stuff/8.jpg'
import stuff9 from '../img/stuff/9.jpg'
import stuff10 from '../img/stuff/10.jpg'
import stuff11 from '../img/stuff/11.jpg'
import stuff12 from '../img/stuff/12.jpg'
import stuff13 from '../img/stuff/13.jpg'
import Aos from "aos";
import phoneOld from "../img/phone-fiolet.svg";

const Stuff = () => {

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({behavior: 'smooth'}, 500)
        document.body.style.overflowX = 'hidden';
        if (window.innerWidth > 900) {
            Aos.init({duration: 1000, delay: 0, once: true});
            Aos.refresh();
        } else if (window.innerWidth <= 900) {
            let dataAos = document.querySelectorAll('#data-aos');
            for  (let data of dataAos) {
                delete data.dataset.aos
            }
        }
    }, []);

    return <div className='stuff container'>
        <Helmet >
            <html lang="ru" />
            <title>Наши грузчики</title>
            <meta name="keywords" content="грузчики, вежливый переезд, гатчина, СПБ, ленинградская область" />
            <meta name="description" content="Мы предлагаем профессиональных грузчиков, которые не будут грубить, делать перекуры каждые 5 минут, и тянуть время. Мы позаботимся о том, чтобы Ваш переезд был комфортным!" />
        </Helmet>
        <div className="phones flex-container">
            <a href="tel:8 (911) 247-11-32" className='phone-home'><img src={phoneOld} /> 8 (911) 247-11-32</a>
            <a href="tel:8 (911) 923-24-99" className='phone-home'><img src={phoneOld} />8 (911) 923-24-99</a>
        </div>
        <h1>Грузчики</h1>
        <div id='data-aos' data-aos="fade-left">
            <p>
                Наша компания позиционирует не обычный переезд, а вежливый переезд.
                Согласитесь, лучше когда приходят, приятные улыбающиеся грузчики, и водитель,
                который не будет вам грубить и портить настроение своим видом и отношением к Вам.
                Поверте, это не пустые слова, это правда жизни, если вы думаете, что вы заплатили деньги,
                и вам будут очень рады!? Ничего подобного! Всё может быть наоборот.
            </p>
            <p>
                Мы предлагаем, профессиональных грузчиков, которые не будут грубить, делать перекуры через каждые 5 минут, и тянуть время.
                Мы позаботимся о том, чтобы Ваш переезд был комфортным!
            </p>
        </div>

        <div className="swiper stuff__container container" id='data-aos' data-aos="fade-right">
            <Swiper
                className="swiper-wrapper"
                slidesPerView={3}
                centeredSlides={true}
                spaceBetween={10}
                modules={[Navigation, Pagination]}
                loop={true}
                navigation={{
                    prevEl: "#swiperPrevStuff",
                    nextEl: "#swiperNextStuff"
                }}
                pagination={{
                    clickable: true,
                    el: "#swiperPaginationStuff"
                }}
                breakpoints={{
                    '100': {
                        'slidesPerView': 1,
                        'slidesPerGroup': 1,
                        'spaceBetween': 30,
                        'centeredSlides': true
                    },
                    '750': {
                        'slidesPerView': 2,
                        'slidesPerGroup': 1,
                        'spaceBetween': 10,
                        'centeredSlides': false
                    },
                    '1200': {
                        'slidesPerView': 2,
                        'slidesPerGroup': 2,
                        'spaceBetween': 10,
                        'centeredSlides': false
                    },
                    '1600': {
                        'slidesPerView': 3,
                        'slidesPerGroup': 1,
                        'spaceBetween': 10,
                        'centeredSlides': true
                    }
                }}
            >
                <SwiperSlide className="swiper-slide">
                    <img src={stuff4} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff13} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff3} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff1} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff2} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff6} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff7} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff8} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff9} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff10} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff11} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff12} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={stuff5} alt='Грузотаксишка Грузчики'/>
                </SwiperSlide>
                <div className="swiper-pagination" id='swiperPaginationStuff'/>
            </Swiper>
            <div className="swiper-button-next" id='swiperNextStuff'/>
            <div className="swiper-button-prev" id='swiperPrevStuff'/>
        </div>
    </div>
};

export default Stuff;