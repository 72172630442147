import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import Aos from "aos";

import {Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import car1 from '../img/cars/1.jpg'
import car2 from '../img/cars/2.jpg'
import car3 from '../img/cars/3.jpg'
import car4 from '../img/cars/4.jpg'
import car5 from '../img/cars/5.jpg'
import car6 from '../img/cars/6.jpg'
import car7 from '../img/cars/7.jpg'
import car8 from '../img/cars/8.jpg'
import car9 from '../img/cars/9.jpg'
import car10 from '../img/cars/10.jpg'
import phoneOld from "../img/phone-fiolet.svg";

const Cars = () => {

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({behavior: 'smooth'}, 500)
        document.body.style.overflowX = 'hidden';
        if (window.innerWidth > 900) {
            Aos.init({duration: 1000, delay: 0, once: true});
            Aos.refresh();
        } else if (window.innerWidth <= 900) {
            let dataAos = document.querySelectorAll('#data-aos');
            for  (let data of dataAos) {
                delete data.dataset.aos
            }
        }
    }, []);

    return <div className='cars container'>
        <Helmet >
            <html lang="ru" />
            <title>Грузотаксишка - Наши машины</title>
            <meta name="description" content="Мы имеем в своем распоряжении большой автомобильный парк грузовых машин. И если Вам нужна машина для переезда, наша компания рада предложить свои услуги." />
            <meta name="Keywords" content="машина для переезда, Грузотаксишка, перевозка грузов, грузоперевозки, минимальные сроки, низкая стоимость"/>
        </Helmet>
        <div className="phones flex-container">
            <a href="tel:8 (911) 247-11-32" className='phone-home'><img src={phoneOld} /> 8 (911) 247-11-32</a>
            <a href="tel:8 (911) 923-24-99" className='phone-home'><img src={phoneOld} />8 (911) 923-24-99</a>
        </div>
        <h1>Машины</h1>
        <div id='data-aos' data-aos="fade-left">
            <p>
                Мы имеем в своем распоряжении большой автомобильный парк грузовых машин.
                И если Вам нужна машина для переезда, наша компания рада предложить свои услуги.
                Для Ваc мы подберем наиболее подходящий и оптимальный грузовой транспорт,
                а также предоставим бригаду квалифицированных грузчиков в минимальные сроки.
            </p>

            <p>
                Вы сможете у нас заказать машину для переезда и любых других транспортных нужд и потребностей.
                Выбор транспортных задач, которые мы способны решить, довольно обширен.
                Любая грузоперевозка с нашими автомобилями чрезвычайно проста и эффективна.
                Заказать авто для перевозки мебели предельно просто! Если вам нужно авто для квартирного или офисного переезда,
                компания «Грузотаксишка» подберет Вам машины нужных размеров.
            </p>
            <p>Заказать машину для перевозки груза возможно прямо сейчас, позвонив по телефонам указанным на сайте.</p>
            <p>
                Потребность в грузовой машине возникает и у предприятия, и у частного лица.
                Например, необходима машина для перевозки грузов, чтоб перевезти мебель или бытовую технику.
                Для доставки сырья на предприятие или товара клиенту, а так же перевозку мебели, товара, или любого груза Вам так же понадобится грузовой автомобиль.
            </p>
            <p>
                На сегодняшний день потребность в авто для грузоперевозки довольно высока в любых сферах.
                При этом видов грузовых машин существует множество.
                Наша компания подберет грузовой автомобиль, который будет отвечать Вашим требованиям, так как мы располагаем широким автопарком.
            </p>
            <p>Заказав машину для грузоперевозок у нас, Вы сможете решить все вопросы в минимальные сроки и по низкой стоимости!</p>
        </div>

        <div className="swiper cars__container container" id='data-aos' data-aos="fade-right">
            <Swiper
                className="swiper-wrapper"
                slidesPerView={3}
                centeredSlides={true}
                spaceBetween={10}
                modules={[Navigation, Pagination]}
                loop={true}
                navigation={{
                    prevEl: "#swiperPrevCars",
                    nextEl: "#swiperNextCars"
                }}
                    pagination={{
                        clickable: true,
                        el: "#swiperPaginationCars"
                    }}
                breakpoints={{
                    '100': {
                        'slidesPerView': 1,
                        'slidesPerGroup': 1,
                        'spaceBetween': 30,
                        'centeredSlides': true
                    },
                    '750': {
                        'slidesPerView': 2,
                        'slidesPerGroup': 1,
                        'spaceBetween': 10,
                        'centeredSlides': false
                    },
                    '1200': {
                        'slidesPerView': 2,
                        'slidesPerGroup': 2,
                        'spaceBetween': 10,
                        'centeredSlides': false
                    },
                    '1600': {
                        'slidesPerView': 3,
                        'slidesPerGroup': 1,
                        'spaceBetween': 10,
                        'centeredSlides': true
                    }
                }}
            >
                <SwiperSlide className="swiper-slide">
                    <img src={car6} alt='Грузотаксишка Машины'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={car8} alt='Грузотаксишка Машины'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={car3} alt='Грузотаксишка Машины'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={car4} alt='Грузотаксишка Машины'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={car10} alt='Грузотаксишка Машины'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={car1} alt='Грузотаксишка Машины'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={car7} alt='Грузотаксишка Машины'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={car2} alt='Грузотаксишка Машины'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={car9} alt='Грузотаксишка Машины'/>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <img src={car5} alt='Грузотаксишка Машины'/>
                </SwiperSlide>
                <div className="swiper-pagination" id='swiperPaginationCars'/>
            </Swiper>
            <div className="swiper-button-next" id='swiperNextCars'/>
            <div className="swiper-button-prev" id='swiperPrevCars'/>
        </div>
    </div>
};

export default Cars;