import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import Aos from "aos";

import Header from "../components/Header";


function NotFound() {

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({behavior: 'smooth'}, 500)
        document.body.style.overflowX = 'hidden';
        if (window.innerWidth > 900) {
            Aos.init({duration: 1000, delay: 0, once: true});
            Aos.refresh();
        } else if (window.innerWidth <= 900) {
            let dataAos = document.querySelectorAll('#data-aos');
            for  (let data of dataAos) {
                delete data.dataset.aos
            }
        }
    }, []);

    return <>
        <Helmet >
            <html lang="ru" />
            <title>404</title>
            <meta name='description' content="404"/>
        </Helmet>
        <Header />
        <div className="content__404">

            <div className="container container__404 flex-container" >
                <h1 id='data-aos' data-aos="fade-right">Упс, <br/>Такая страница не существует или находится в разработке...</h1>
                <Link className="btn btn__404 btn__blue" to='/' id='data-aos' data-aos="fade-left">На главную</Link >
            </div>
        </div>
    </>
}

export {NotFound};