import {useTransition} from "react-spring";
import {Routes, Route, useLocation} from 'react-router-dom'
import Home from "../pages/Home";
import {Layout} from "./Layout";
import React, {useState} from "react";
import Services from "../pages/Services";
import Cars from "../pages/Cars";
import Stuff from "../pages/Stuff";
import Partners from "../pages/Partners";
import {NotFound} from "../pages/NotFound";
import {Contacts} from "../pages/Contacts";
import Reviews from "../pages/Reviews";
import Modal from "./modal/Modal";

const AppRouter = () => {

    const [modalActive, setModalActive] = useState(false)
    const [modalCallback, setModalCallback] = useState(false)
    const [contextModal, setContextModal] = useState('')


    const location = useLocation();
    const transition = useTransition(location, (location)=> location.pathname, {
        from: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
        },
        leave: {
            opacity: 1,
        },
    })

    return <>
            <Routes>
                <Route path='/' element={
                    <Layout
                        location={location}
                        transition={transition}
                        setModalActive={setModalActive}
                        setModalCallback={setModalCallback}
                    />
                }>
                    <Route index element={<Home setModalActive={setModalActive} setContextModal={setContextModal}/>} />

                    <Route path='uslugi-i-tseny' element={<Services setModalActive={setModalActive} setContextModal={setContextModal}/>} />
                    <Route path='nashi-mashini' element={<Cars />} />
                    <Route path='nashi-gruzchiki' element={<Stuff />} />
                    <Route path='partners' element={<Partners />} />
                    <Route path='otzyvy' element={<Reviews />} />
                    <Route path='kontakty' element={<Contacts />} />
                </Route>
                <Route path='*' element={<NotFound />} />
            </Routes>
            <Modal
                modalActive={modalActive}
                setModalActive={setModalActive}
                modalCallback={modalCallback}
                setModalCallback={setModalCallback}
                setContextModal={setContextModal}
                contextModal={contextModal}
            />
        </>
};

export default AppRouter;