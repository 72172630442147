import {Outlet} from 'react-router-dom'
import {animated} from "react-spring";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Layout(props) {

    const {transition, setModalActive, modalCallback, setModalCallback} = props

    return <div className='wrapper'>
        <Header  setModalActive={setModalActive} setModalCallback={setModalCallback}/>
        {transition.map(({item, props, key})=>(
            <animated.main key={key} style={props} >
                <Outlet />
            </animated.main>
        ))}
        <Footer />
    </div>
}

export {Layout}
