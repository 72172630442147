import React, {useEffect, useState} from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import CSSTransition from "react-transition-group/CSSTransition";
import errorAlert from "../../img/cursors/error-alert.svg";
import successAlert from "../../img/cursors/success-alert.svg";
import {sendMail} from "../../http/SendMailAPI";
import Home from "../../pages/Home";
const Modal = ({modalActive, setModalActive, setModalCallback, modalCallback,  setContextModal, contextModal}) => {

    const [sendError, setSendError] = useState(false)
    const [sendSuccess, setSendSuccess] = useState(false)
    const [sendLoading, setSendLoading] = useState(false)

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('Поле не может быть пустым')
    const [nameDirty, setNameDirty] = useState(false)

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('Поле не может быть пустым')
    const [emailDirty, setEmailDirty] = useState(false)

    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState('Поле не может быть пустым')
    const [phoneDirty, setPhoneDirty] = useState(false)

    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState('Поле не может быть пустым')
    const [messageDirty, setMessageDirty] = useState(false)

    const [formValid, setFormValid] = useState(false)

    const  handleCloseModal = ()=> {
        setTimeout(()=>{
            setModalCallback(false)
        }, 1000)
        setModalActive(false)
        setSendError(false)
        setSendSuccess(false)
        setSendLoading(false)
        setName('')
        setNameError('Поле не может быть пустым')
        setNameDirty(false)
        setEmail('')
        setEmailError('Поле не может быть пустым')
        setEmailDirty(false)
        setPhone('')
        setPhoneError('Поле не может быть пустым')
        setPhoneDirty(false)
        setMessage('')
        setMessageError('Поле не может быть пустым')
        setMessageDirty(false)
        setFormValid(false)
        setContextModal('')
        document.body.style.overflowY = 'scroll';
    }

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'email' :
                setEmailDirty(true)
                break;
            case 'name' :
                setNameDirty(true)
                break;
            case 'phone' :
                setPhoneDirty(true)
                break;
            case 'message' :
                setMessageDirty(true)
                break;
        }
    }

    const nameHandler = (e) => {
        setName(e.target.value)
        if (e.target.value === '' || !e.target.value.length){
            setNameError('Поле не может быть пустым')
        } else{
            setNameError('')
        }
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
        if (e.target.value === '' || !e.target.value.length){
            setEmailError('Поле не может быть пустым')
        } else{
            setEmailError('')
        }
    }

    const phoneHandler = (e) => {
        setPhone(e.target.value.replace(/\D/g, ''))
        if (e.target.value === '' || !e.target.value.length){
            setPhoneError('Поле не может быть пустым')
        } else{
            setPhoneError('')
        }
    }

    const messageHandler = (e) => {
        setMessage(e.target.value)
        if (e.target.value === '' || !e.target.value.length){
            setMessageError('Поле не может быть пустым')
        } else{
            setMessageError('')
        }
    }

    const handlerSend  = async (e) => {
        e.preventDefault()
        try {
            setSendLoading(true)
            await sendMail(name, email, phone, message, modalCallback ? 'callbackForm' : 'consultationForm', contextModal)
            setTimeout(()=>{
                setSendSuccess(true)
                setName('')
                setPhone('')
                setEmail('')
                setMessage('')
                setFormValid(false)
                setSendLoading(false)
            }, 1000)
        } catch (error) {
            setSendLoading(true)
            setTimeout(()=>{
                setSendError(true)
                setSendLoading(false)
                console.error(error)
            }, 1000)
        }
    }

    useEffect(()=>{
        if (modalCallback && (nameError || phoneError)){
            setFormValid(false)
        } else if (!modalCallback && (nameError || phoneError || emailError || messageError)) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [nameError, emailError, phoneError, messageError])

    return  <div className={modalActive ? "modal active" : "modal"} onClick={handleCloseModal}>
        { sendLoading ? <div className='waiting-to-be-sent'><CircularProgress color="secondary" size={120} /></div> : null }
        <div
            className={modalActive ? 'modal-content active' : "modal-content"}
            onClick={(e) => e.stopPropagation()}
        >
            <span className="close" onClick={handleCloseModal}/>
            <form>
                <h1>Связаться с нами</h1>
                <CSSTransition in={sendError} classNames='alert' timeout={300} unmountOnExit>
                    <div className="error-alert">
                        <img src={errorAlert} alt="error"/>
                        <p>Ошибка при отправке! Попробуйте перезагрузить страницу и повторить</p>
                    </div>
                </CSSTransition>
                <CSSTransition in={sendSuccess} classNames='alert' timeout={300} unmountOnExit>
                    <div className="success-alert">
                        <img src={successAlert} alt="success"/>
                        <p>Форма успешно отправлена!</p>
                    </div>
                </CSSTransition>
                <div className="form-group form-group__input">
                    <label className="preText">Имя</label>
                    <input
                        type="text"
                        name="name"
                        value={name}
                        onBlur={e => blurHandler(e)}
                        className={nameDirty && nameError ? 'error' : ''}
                        placeholder={nameDirty && nameError  ? 'Поле Имя необходимо заполнить' : 'Имя'}
                        onChange={e=>nameHandler(e)}
                    />
                    {
                        modalCallback ? null : <>
                                <label className="preText">Почта</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onBlur={e => blurHandler(e)}
                                    className={emailDirty && emailError ? 'error' : ''}
                                    placeholder={emailDirty && emailError  ? 'Поле Email необходимо заполнить' : 'Email'}
                                    onChange={e=>emailHandler(e)}
                                />
                            </>
                    }

                    <label className="preText">Телефон</label>
                    <input
                        type="tel"
                        name="phone"
                        value={phone}
                        onBlur={e => blurHandler(e)}
                        className={phoneDirty && phoneError ? 'error' : ''}
                        placeholder={phoneDirty && phoneError  ? 'Поле Телефон необходимо заполнить' : 'Телефон'}
                        onChange={e=>phoneHandler(e)}
                    />
                    {
                        modalCallback ? null : <>
                                <label className="preText">Ваш запрос</label>
                                <textarea
                                    type="text"
                                    name="message"
                                    value={message}
                                    onBlur={e => blurHandler(e)}
                                    className={messageDirty && messageError ? 'error' : ''}
                                    placeholder={messageDirty && messageError  ? 'Поле Комментарий необходимо заполнить' : 'Комментарий'}
                                    onChange={e=>messageHandler(e)}
                                />
                            </>
                    }

                </div>
                <button
                    type="submit"
                    className="btn modal-btn"
                    disabled={!formValid}
                    onClick={e=>handlerSend(e)}
                >
                    Отправить
                </button>
            </form>
        </div>
    </div>
};

export default Modal;