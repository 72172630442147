import React, {useEffect, useState} from 'react';
import phone1 from "../img/phone-yellow.svg"
import mail from "../img/email-yellow.svg"
import vk from "../img/vk-yellow.svg"
import phoneOld from "../img/phone-fiolet.svg";
import errorAlert from "../img/cursors/error-alert.svg";
import successAlert from "../img/cursors/success-alert.svg";
import {sendMail} from "../http/SendMailAPI";
import Aos from "aos";
import {Helmet} from "react-helmet";
import CSSTransition from "react-transition-group/CSSTransition";
import CircularProgress from '@mui/material/CircularProgress';

const Contacts = () => {

    const [sendError, setSendError] = useState(false)
    const [sendSuccess, setSendSuccess] = useState(false)
    const [sendLoading, setSendLoading] = useState(false)

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('Поле не может быть пустым')
    const [nameDirty, setNameDirty] = useState(false)

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('Поле не может быть пустым')
    const [emailDirty, setEmailDirty] = useState(false)

    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState('Поле не может быть пустым')
    const [phoneDirty, setPhoneDirty] = useState(false)

    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState('Поле не может быть пустым')
    const [messageDirty, setMessageDirty] = useState(false)

    const [formValid, setFormValid] = useState(false)

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'email' :
                setEmailDirty(true)
                break;
            case 'name' :
                setNameDirty(true)
                break;
            case 'phone' :
                setPhoneDirty(true)
                break;
            case 'message' :
                setMessageDirty(true)
                break;
        }
    }

    const nameHandler = (e) => {
        setName(e.target.value)
        if (e.target.value === '' || !e.target.value.length){
            setNameError('Поле не может быть пустым')
        } else{
            setNameError('')
        }
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
        if (e.target.value === '' || !e.target.value.length){
            setEmailError('Поле не может быть пустым')
        } else{
            setEmailError('')
        }
    }

    const phoneHandler = (e) => {
        setPhone(e.target.value.replace(/\D/g, ''))
        if (e.target.value === '' || !e.target.value.length){
            setPhoneError('Поле не может быть пустым')
        } else{
            setPhoneError('')
        }
    }

    const messageHandler = (e) => {
        setMessage(e.target.value)
        if (e.target.value === '' || !e.target.value.length){
            setMessageError('Поле не может быть пустым')
        } else{
            setMessageError('')
        }
    }

    const handlerSend  = async (e) => {
        e.preventDefault()
        try {
            setSendLoading(true)
            await sendMail(name, email, phone, message, 'consultationForm')
            setTimeout(()=>{
                setSendSuccess(true)
                setName('')
                setPhone('')
                setEmail('')
                setMessage('')
                setFormValid(false)
                setSendLoading(false)
            }, 1000)
        } catch (error) {
            setSendLoading(true)
            setTimeout(()=>{
                setSendError(true)
                setSendLoading(false)
                console.error(error)
            }, 1000)
        }
    }

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({behavior: 'smooth'}, 500)
        document.body.style.overflowX = 'hidden';
        if (window.innerWidth > 900) {
            Aos.init({duration: 1000, delay: 0, once: true});
            Aos.refresh();
        } else if (window.innerWidth <= 900) {
            let dataAos = document.querySelectorAll('#data-aos');
            for  (let data of dataAos) {
                delete data.dataset.aos
            }
        }
    }, []);

    useEffect(()=>{
         if (nameError || phoneError || emailError || messageError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [nameError, emailError, phoneError, messageError])

    return <div className='contacts'>
        <Helmet >
            <html lang="ru" />
            <title>Грузотаксишка - Контакты</title>
            <meta name="Description" content="Контамктная информация компании грузотаксишка. Телефон: 8(911)247-11-32. Почта: info@gruzotaxishka.ru. Группа ВК: http://vk.com/club34672925" />
            <meta name="Keywords" content="переезд, свяжитесь с нами, грузотаксишка, контакты, телефон, электронная почта" />
        </Helmet>
        <div className="phones flex-container">
            <a href="tel:8 (911) 247-11-32" className='phone-home'><img src={phoneOld} alt='телефон грузотаксишка'/> 8 (911) 247-11-32</a>
            <a href="tel:8 (911) 923-24-99" className='phone-home'><img src={phoneOld} alt='телефон грузотаксишка'/>8 (911) 923-24-99</a>
        </div>

        <h1>Контакты</h1>
        <section className="contacts-page">
            <div className="white-block container" id='data-aos' data-aos="fade-right">
                { sendLoading ? <div className='waiting-to-be-sent'><CircularProgress color="primary" size={120} /></div> : null }

                <div className="flex-container">
                    <div className="callback-form flex-block">
                        <h3 className="blue-color">Свяжитесь с нами</h3>
                        <form>
                            <div className="form__content">
                               <CSSTransition in={sendError} classNames='alert' timeout={300} unmountOnExit>
                                   <div className="error-alert">
                                       <img src={errorAlert} alt="error"/>
                                       <p>Ошибка при отправке! Попробуйте перезагрузить страницу и повторить</p>
                                   </div>
                               </CSSTransition>
                                <CSSTransition in={sendSuccess} classNames='alert' timeout={300} unmountOnExit>
                                    <div className="success-alert">
                                        <img src={successAlert} alt="success"/>
                                        <p>Данные формы успешно отправлены! Мы ответим Вам в ближайшее время</p>
                                    </div>
                                </CSSTransition>
                                <div className="form-group">
                                    <span className="preText">Имя</span>
                                    <input
                                        type="text"
                                        name="name"
                                        value={name}
                                        onBlur={e => blurHandler(e)}
                                        className={nameDirty && nameError ? 'error' : ''}
                                        placeholder={nameDirty && nameError  ? 'Поле Имя необходимо заполнить' : 'Имя'}
                                        onChange={e=>nameHandler(e)}
                                    />
                                    <span className="preText">E-mail</span>
                                    <input
                                        type="email"
                                        name="email"
                                        value={email}
                                        onBlur={e => blurHandler(e)}
                                        className={emailDirty && emailError ? 'error' : ''}
                                        placeholder={emailDirty && emailError  ? 'Поле Email необходимо заполнить' : 'Email'}
                                        onChange={e=>emailHandler(e)}
                                    />
                                    <span className="preText">Телефон</span>
                                   <input
                                        type="tel"
                                        name="phone"
                                        data-tel-input={true}
                                        maxLength="18"
                                        value={phone}
                                        onBlur={e => blurHandler(e)}
                                        className={phoneDirty && phoneError ? 'error' : ''}
                                        placeholder={phoneDirty && phoneError  ? 'Поле Телефон необходимо заполнить' : 'Телефон'}
                                        onChange={e=>phoneHandler(e)}
                                    />
                                    <span className="preText">Дополнительный комментарий</span>
                                    <textarea
                                        type="text"
                                        name="message"
                                        value={message}
                                        onBlur={e => blurHandler(e)}
                                        className={messageDirty && messageError ? 'error' : ''}
                                        placeholder={messageDirty && messageError  ? 'Поле Комментарий необходимо заполнить' : 'Комментарий'}
                                        onChange={e=>messageHandler(e)}
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn__blue"
                                    disabled={!formValid}
                                    onClick={e=>handlerSend(e)}
                                >
                                    Отправить
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="contact-information flex-block">
                        <h3>Контактная информация</h3>
                        <div className="flex-container">
                            <div className="inform-elem">
                                <img src={mail} alt='mail'/>
                                <a href="mailto:info@gruzotaxishka.ru">info@gruzotaxishka.ru</a>
                            </div>
                            <div className="inform-elem">
                                <img src={phone1}  alt='phone'/>
                                    <a href="tel:8 (911) 247-11-32">8 (911) 247-11-32</a>
                            </div>
                            <div className="inform-elem">
                                <img src={phone1}  alt='phone'/>
                                    <a href="tel:8 (911) 923-24-99">8 (911) 923-24-99</a>
                            </div>
                            <div className="inform-elem">
                                <img src={vk}  alt='vk'/>
                                <a href="https://vk.com/club.gruzotacsichka47">club.gruzotacsichka47</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
};

export { Contacts };